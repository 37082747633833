const originalError = window.console.error;

window.console.error = function (message: any, ...args: any[]) {
  if (
    typeof message === 'string' &&
    (message?.includes('findDOMNode is deprecated') ||
      message?.includes('changing an uncontrolled input to be controlled') ||
      message?.includes('defaultProps will be removed'))
  ) {
    console.warn(`\x1b[33mSuppressed (suppressErrors.ts):\x1b[0m ${message}`);
    return;
  }
  originalError.apply(console, [message, ...args]);
};

export {};
